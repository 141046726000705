interface ErrorResponse {
  message: string;
  errors: Record<string, string[]>;
}

export default (response?: ErrorResponse, formEl?: any) => {
  if (!response || !formEl) {
    return;
  }

  const errors = response.errors || {};

  if (typeof formEl.setErrors === "function") {
    console.log(errors);
    formEl.setErrors(errors);
  } else if (typeof formEl.value.setErrors === "function") {
    formEl.value.setErrors(errors);
  }

  return;
};
